import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import { WIDTH_MD } from 'src/utils/breakpoints'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import { Section } from './style'

const GaloVerso = () => {
  const windowWidth = useWidth(100)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-7 offset-lg-0 d-flex justify-content-center justify-content-lg-start order-md-last flex-md-column pl-lg-5 pl-xl-4'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/arena-mrv-1/image.webp'
              altDescription='Arena MRV e Galo'
              arrayNumbers={[ 312, 335, 414, 582 ]}
              arrayNumbersHeight={[ 255, 277, 342, 481 ]}
            />
            {windowWidth >= WIDTH_MD &&
              <p className='fs-10 lh-12 text-white mb-0 text-md-right text'>
                <span className='d-block'>Isto não é patrocinado, apoiado ou</span> administrado pela Epic Games, Inc.
              </p>
            }
          </div>
          <div className='ccol-12 col-md-6 col-lg-5'>
            <h2 className='fs-32 fs-xl-48 lh-40 lh-xl-60 text-grayscale--500 fw-600 mt-4'>
              <span className='d-block d-md-inline'>GaloVerso no</span> modo Fortinite Criativo
            </h2>
            <p className='fs-18 lh-22 text-orange--extra fw-400 mt-3 mb-4'>
              <span className='d-md-block'>A Arena MRV marca presença</span> <span className='d-md-block'>num dos games mais jogados</span> do ano.
            </p>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/arena-mrv/image.webp'
              altDescription='Logo Fortnite'
              arrayNumbers={[ 223, 167, 187, 264, 264 ]}
              arrayNumbersHeight={[ 86, 64, 72, 102, 102 ]}
            />
            <Link
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Confira como turbinar seu Mundo Gamer',
                  section_name: 'GaloVerso no modo Fortinite Criativo',
                  redirect_url: '/games/',
                })
              }}
              to='/games/'
              target='_blank'
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-4 mt-md-5 px-0 mw-100'
            >
              Confira como turbinar seu Mundo Gamer
            </Link>
            {windowWidth < WIDTH_MD &&
              <p className='fs-10 lh-12 text-grayscale--500 mt-4 mb-0'>
                <span className='d-block'>Isto não é patrocinado, apoiado ou</span> administrado pela Epic Games, Inc.
              </p>
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GaloVerso
